import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { api } from '../api/api';

import useSession from '../hooks/useSession';

export interface ISession {
  token?: string | null;
  myself?: IUserCurrent | null;
}

export interface IUserCurrent {
  id: string;
  verified: boolean;
  scope: string;
  userName: string;
  primaryTeamId: string;
  createdAt: string;
  updatedAt: string;
  avatarUri: string;
  avatar: string; // PREFERRED, has fallback
  referralId: string;
  disabled: boolean;
  roles: {
      id: string;
      name: string;
  }[];
}

export const sessionName = process.env.REACT_APP_SESSION_NAME || 'user:jwt';

const defaultSession: ISession = {
  token: null,
  myself: null,
};

const activeSession: ISession = {
  ...defaultSession,
  token: localStorage.getItem(sessionName) || null,
}

const SessionContext = createContext({ session: activeSession, setSession: (session: ISession) => {}, logout: (reason?: string | undefined) => {} });

export const SessionContextProvider = ({ children }: any) => {
  const [session, updateSession] = useState<ISession>(activeSession);

  const setSession = useCallback((data: any) => {
    localStorage.setItem(sessionName, data.token);
    
    updateSession(data);
  }, []);

  useEffect(() => {
    if ( session?.token !== null ) {
      localStorage.setItem(sessionName, session.token as string);
    }
  }, [session.token]);

  return (
    <SessionContext.Provider
      value={{
        session,
        setSession,
        logout: (reason = undefined) => {
          localStorage.removeItem(sessionName);

          updateSession(defaultSession);

          Swal.fire({
            title: 'Logged Out',
            text: reason || '',
            icon: 'success',
            timer: 3000,
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
          });
        }
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const UserGetter = () => {
  const { session, setSession } = useSession();

  const getUser = useCallback(async () => {
    const myself = await api.getCurrent();

    setSession({
      ...session,
      myself,
    });
  }, [session.token, setSession]);

  useEffect(() => {
    if (session?.token) {
      getUser();
    }
  }, [getUser]);

  return null;
}

export default SessionContext;

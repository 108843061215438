import clsx from 'clsx';
import styles from './CircleProgress.module.scss';

interface CircleProgressProps {
  progress?: number;
  type?: 'determinate' | 'indeterminate' | undefined;
  size?: number;
  thickness?: number;
  color?: string;
  className?: string;
}

const CircleProgress = ({ className, progress, size, thickness, color }: CircleProgressProps) => {
  return <div
    className={ clsx( styles.loader, className) }
    style={{
      width: size || 42,
      height: size || 42
    }}
  />
}

export default CircleProgress;
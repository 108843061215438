/* eslint-disable no-restricted-globals */

export function openWindow(link: string = 'about:blank', width: number = 420, height: number = 700) {
	let x = window.screenLeft;
	let y = window.screenTop;
	const zPortWidth = window.innerWidth
		? window.innerWidth
		: document.documentElement.clientWidth
			? document.documentElement.clientWidth
			: screen.width;
	const zPortHeight = window.innerHeight
		? window.innerHeight
		: document.documentElement.clientHeight
			? document.documentElement.clientHeight
			: screen.height;
	x = zPortWidth / 2 - width / 2 + x;
	y = zPortHeight / 2 - height / 2 + y;
	const win = window.open(
		link,
		'_blank',
		`toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbar=no,resizable=no,scrollbars=yes,width=${width},height=${height},top=${y},left=${x}`,
	);

	return win;
}

import clsx from 'clsx';
import Color from 'color';
import { ReactElement, useState } from 'react';

import styles from './Button.module.scss';
import CircleProgress from './CircleProgress';
import Icon, { IconProps } from './Icon';
import Tooltip, { ToolTipProps } from './Tooltip';

        
interface IButton {
  icon?: ReactElement<IconProps> | null;
  toggle?: true;
  active?: boolean;
  type?: 'button' | 'submit' | 'reset';
  badge?: true | string | number;
  href?: string;
  progress?: boolean | number;
  onClick?: () => void;
  tooltip?: ToolTipProps | false;
  fullWidth?: true;
  className?: string;
  children?: any;
  size?: 'small' | 'medium' | 'large';
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | `var(--${string})` | `#${string}`;
  disabled?: boolean;
  centerText?: true;
  exact?: true
  autofocus?: boolean;
}

interface Button {
  className?: string;
}

const Btn = ({ autofocus, exact, centerText, children, variant, type, icon, color, toggle, active, badge, href, progress, onClick, tooltip, disabled, fullWidth, size, className: topClassName }: IButton) => {
  const [loading] = useState(!!progress);

  const isActive = active || false;

  const B = ({ className }: Button) =>
    <button
      autoFocus={autofocus}
      type={type ?? 'button'}
      className={ clsx(styles.button, topClassName, className, {
        [styles.focus]: loading,
        [styles.active]: isActive,
        [styles[color || 'primary']]: true,
        [styles[variant || 'filled']]: true,
        [styles.fullwidth]: fullWidth,
        [styles[size || 'medium']]: true,
        [styles.disabled]: disabled
      }) }
      style={{
        "--color-button-active": color?.[0] === '#' && color,
        "--color-button-hover-active": color?.[0] === '#' && Color(color).lighten(0.2),
        justifyContent: centerText ? 'center' : 'inherit',
      } as any}
      onClick={ !disabled ? onClick : () => null }
    >
      <span>
        { icon &&
          <span className={ styles.iconContainer }>
            { (loading || (progress && progress > 0))
              ? <CircleProgress
                type={ progress && progress > 1 ? "determinate": "indeterminate" }
                progress={ progress as number }
                size={ 20 }
                thickness={ 5 }
                color="inherit"
              />
              : icon
            }
            { !!badge && <b className={ styles.badge }>{ badge }</b> }
          </span>
        }
        { children }
      </span>
      { toggle && <Icon name="chevron-down" solid className={ styles.dd } /> }
      { (!!badge && !icon) && <b className={ styles.badge }>{ badge }</b> }
    </button>;

  return <Tooltip {...tooltip}>
    <B className={ styles.anchorButton } />
  </Tooltip>
}

export default Btn;

import { sessionName } from '../contexts/sessionContext';

export interface IHTTPResponse {
    statusCode: number;
    error: string;
    message: string;
}

export interface IService {
    name: string;
    canLogin: boolean;
    canLink: boolean;
    canBePrimary: boolean;
}

export interface IChannel {
    id: string;
    name: string;
    status: IStatus[];
    user: IUserCurrent;
    commands?: ICommand[];
    aliases?: IAlias[];
    quotes?: IQuote[];
    variables?: IVariable[];
}

export interface IError {
    error: string;
    message: string;
    statusCode: number;
    validation: {
        [key: string]: string[];
    };
}

export interface ICommand {
    id?: string;
    name: string;
    message: string;
    state: number;
    services: ('all' | ' discord' | 'twitch' | 'glimesh' | 'trovo')[];
    cooldown: number;
    userRole: 'User' | 'Subscriber' | 'Regular' | 'Moderator' | 'Owner';
}

export interface IAlias {
    id?: string;
    channelId?: string;
    name: string;
    command: string;
    args?: string[];
    createdAt?: string;
    updatedAt?: string;
}

export interface IQuote {
    id?: string;
    userName?: string;
    message: string;
    game: string;
    audience: string;
    streamTime: string;
    createdAt: string;
    deletedAt?: string;
}

export interface IVariable {
    id?: string;
    name: string;
    value: number;
    channelId?: string;
    offlineReset: boolean;
    createdAt?: string;
    updatedAt?: string;
}

export interface IOverlay {
    id?: string;
    name?: string;
    elements?: any[];
    dimensions?: {
        name?: string;
        width: number;
        height: number;
    };
    channelId?: string;
    createdAt?: string;
    updatedAt?: string;
}

export enum SubscriptionType {
    BEEPBOT_PRO = 'beepbot_pro',
}

export interface IProject {
    id: string;
    userId: string;
    type: 'beepbot',
    linkedId: string;
    scopes: string[];
    owner: boolean;
    linked: {
        id: string;
        name: string;
        avatar?: string;
        status: IStatus[];
        createdAt?: string;
        updatedAt?: string;
    }
}

export interface IStatus {
    audience?: string | null;
    featured?: boolean;
    game?: string;
    online?: boolean;
    provider?: string;
    providerId?: string;
    startedAt?: string | null;
    title?: string;
}

export interface IUserCurrent {
    id: string;
    verified: boolean;
    scope: string;
    userName: string;
    primaryTeamId: string;
    createdAt: string;
    updatedAt: string;
    avatarUri: string;
    avatar: string; // PREFERRED, has fallback
    referralId: string;
    disabled: boolean;
    roles: {
        id: string;
        name: string;
    }[];
    projects: IProject[];
    subscriptions: {
        id: string;
        userId: string;
        active: boolean;
        type: SubscriptionType,
        lifetime: boolean;
        createdAt: string;
        expiresAt: string;
    }[];
    grants: IUserGrant[];
}

export interface IUserGrant {
    id: string;
    userId: string;
    username: string;
    provider: string;
    profileId: string;
    createdAt: string;
    updatedAt: string;
    primary: boolean;
}

class API {
    private services: IService[] = [
        {
            name: 'twitch',
            canLogin: true,
            canLink: true,
            canBePrimary: true,
        },
        {
            name: 'discord',
            canLogin: false,
            canLink: true,
            canBePrimary: false,
        },
        {
            name: 'glimesh',
            canLogin: false,
            canLink: true,
            canBePrimary: false,
        },
        {
            name: 'trovo',
            canLogin: true,
            canLink: true,
            canBePrimary: true,
        },
        {
            name: 'twitter',
            canLogin: false,
            canLink: true,
            canBePrimary: false,
        },
    ];

    public getServices() {
        return this.services;
    }

    public async getAuthUrl(provider: string, referralId?: string): Promise<{ url: string }> {
        const headers: { [header: string]: string } = {};
        if (localStorage.getItem(sessionName) != null) {
            headers['Authorization'] = localStorage.getItem(sessionName) as string;
        }

        const query: { [ key: string ]: string } = {};

        if (referralId != null) {
            query['referral'] = referralId;
        }

        const parsedQuery = Object.keys(query)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(query[k]))
            .join('&');

        return fetch(`${this.getAPIDomain()}/oauth/${provider}/request?${parsedQuery}`, { headers })
            .then(res => res.json());
    }

    public async getCurrent(jwt = localStorage.getItem(sessionName)): Promise<IUserCurrent> {
        return fetch(`${this.getAPIDomain()}/account/v1/users/current`, {
                headers: {
                    Authorization: `${jwt}`,
                },
            })
            .then(res => res.json())
            .then(res => {
                if (res.statusCode != null && res.statusCode === 401) {
                    throw Error('BAD_SESSION');
                }

                if (res.id != null) {
                    return res;
                }

                return null;
            });
    }

    public async createIntent(plan: string, couponIn: string | null = null) {
        let coupon = couponIn;
        if (coupon == null || coupon.length < 1) {
            coupon = null;
        }

        return fetch(`${this.getAPIDomain()}/account/v1/stripe/create-intent`, {
            headers: {
                Authorization: `${localStorage.getItem('user:jwt')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ product: 'beepbot_pro', plan, coupon }),
            method: 'POST',
        })
        .then(async res => {
            if (res.status < 200 || res.status > 204) {
                return Promise.reject(await res.json());
            }

            return res.json()
                .then(data => {
                    return {
                        clientSecret: data.clientSecret,
                        amount: data.invoice.amount,
                        ...( data.promotionalCode != null && {
                            promoValue: data.promotionalCode.discount,
                        }),
                    };
                });
        });
    }

    public async getStripePortalSession() {
        return fetch(`${this.getAPIDomain()}/account/v1/stripe/create-portal`, {
            headers: {
                Authorization: `${localStorage.getItem('user:jwt')}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
        })
        .then(res => res.json());
    }

    public async getTeams(userId: string): Promise<any> {
        return fetch(`${this.getAPIDomain()}/account/v1/users/${userId}/teams`, {
                headers: {
                    Authorization: `${localStorage.getItem('user:jwt')}`,
                },
            })
            .then(res => res.json())
            .then(res => {
                if (res == null || res.length === 0) {
                    return [];
                }

                return res;
            });
    }

    public async getAccountData<T>(uri: string): Promise<T & IHTTPResponse> {
        return fetch(`${this.getAPIDomain()}/account/v1/${uri}`, {
                headers: {
                    Authorization: `${localStorage.getItem('user:jwt')}`,
                },
            })
            .then(res => res.json())
            .then(res => {
                if (res == null) {
                    return null;
                }

                return res;
            });
    }

    public async setAccountData(uri: string, data: any, fileUpload: boolean = false): Promise<any> {
        const headers: { [header: string]: string } = {};
        if (!fileUpload) {
            headers['Content-Type'] = 'application/json';
        }

        return fetch(`${this.getAPIDomain()}/account/v1/${uri}`, {
                headers: {
                    Authorization: `${localStorage.getItem('user:jwt')}`,
                    ...headers
                },
                method: 'POST',
                body: fileUpload ? data : JSON.stringify(data),
            })
            .then(async res => {
                if (res.status < 200 || res.status > 204) {
                    return Promise.reject(await res.json());
                }
                if (res.status === 204) {
                    return;
                }

                return res.json();
            });
    }

    public async updateAccountData(uri: string, data: any, fileUpload: boolean = false): Promise<any> {
        const headers: { [header: string]: string } = {};
        if (!fileUpload) {
            headers['Content-Type'] = 'application/json';
        }

        return fetch(`${this.getAPIDomain()}/account/v1/${uri}`, {
                headers: {
                    Authorization: `${localStorage.getItem('user:jwt')}`,
                    ...headers
                },
                method: 'PATCH',
                body: fileUpload ? data : JSON.stringify(data),
            })
            .then(async res => {
                if (res.status < 200 || res.status > 204) {
                    return Promise.reject(await res.json());
                }
                if (res.status === 204) {
                    return;
                }

                return res.json();
            });
    }

    public async deleteAccountData(uri: string) {
        const jwt = localStorage.getItem('user:jwt');

        return fetch(`${this.getAPIDomain()}/account/v1/${uri}`, {
                headers: {
                    Authorization: `${jwt}`
                },
                method: 'DELETE'
            })
            .then(async res => {
                if (res.status < 200 || res.status > 204) {
                    return Promise.reject(await res.json());
                }
                if (res.status === 204) {
                    return;
                }

                return res.json();
            });
    }

    public async getDiscordJoin(channelId: string, permCode: number): Promise<any> {
        return fetch(`${this.getAPIDomain()}/beepbot/v2/discord/auth?channel=${channelId}&permCode=${permCode}`, {
                headers: {
                    Authorization: `${localStorage.getItem('user:jwt')}`,
                }
            })
            .then(res => res.json())
            .then(res => {
                if (res == null) {
                    return null;
                }

                return res;
            });
    }

    public async getChannels(): Promise<IChannel[]> {
        return fetch(`${this.getAPIDomain()}/beepbot/v2/channels`)
            .then(res => res.json())
            .then(res => {
                if (res == null || res.length === 0) {
                    return [];
                }

                return res;
            });
    }

    public async getChannel(channel: string): Promise<IChannel> {
        return fetch(`${this.getAPIDomain()}/beepbot/v2/channels/${channel}`, {
                headers: {
                    Authorization: `${localStorage.getItem('user:jwt')}`,
                }
            })
            .then(res => res.json())
            .then(res => {
                if (res.id != null) {
                    return res;
                }

                return null;
            });
    }

    public async createChannel(): Promise<IChannel> {
        return fetch(`${this.getAPIDomain()}/beepbot/v2/channels`, {
                headers: {
                    Authorization: `${localStorage.getItem('user:jwt')}`,
                },
                method: 'POST',
            })
            .then(async res => {
                if (res.status < 200 || res.status > 204) {
                    return Promise.reject(await res.json());
                }

                return res;
            })
            .then(res => res.json())
            .then(res => {
                if (res.statusCode != null && res.statusCode === 400) {
                    return Promise.reject(res);
                }

                return res;
            });
    }

    public async getData<T>(channel: string, type: string, id?: string): Promise<T> {
        return fetch(`${this.getAPIDomain()}/beepbot/v2/channels/${channel}/${type}${ id ? `/${id}`: ''}`, {
                headers: {
                    Authorization: `${localStorage.getItem('user:jwt')}`,
                },
            })
            .then(async res => {
                if (res.status === 204 || res.status === 401) {
                    return null;
                }

                return res.json()
                    .then(res => {
                        if (res == null) {
                            return [];
                        }

                        return res;
                    });
            });
    }

    public async setData<T>(channel: string, type: string, id: string | undefined | null, data?: any): Promise<T> {
        return fetch(`${this.getAPIDomain()}/beepbot/v2/channels/${channel}/${type}${id ? `/${id}` : ''}`, {
                method: id ? 'PATCH': 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `${localStorage.getItem('user:jwt')}`,
                },
                body: JSON.stringify(data),
            })
            .then(async res => {
                if (res.status === 204 || res.status === 401) {
                    return null;
                }

                return res.json()
                    .then(res => {
                        if (res == null) {
                            return [];
                        }

                        return res;
                    });
            });
    }

    public async deleteData(channel: string, type: string, id: string): Promise<any> {
        return fetch(`${this.getAPIDomain()}/beepbot/v2/channels/${channel}/${type}/${id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `${localStorage.getItem('user:jwt')}`,
                },
            })
            .then(async res => {
                if (res.status === 204 || res.status === 401) {
                    return null;
                }

                return res.json()
                    .then(res => {
                        if (res == null) {
                            return [];
                        }

                        return res;
                    });
            });
    }

    public async getExport(uri: string, filename: string): Promise<void> {
        return fetch(`${this.getAPIDomain()}/${uri}`, {
            headers: {
                Authorization: `${localStorage.getItem('user:jwt')}`,
            },
        })
        .then(async res => {
            if (res.status !== 200) {
                return Promise.reject(await res.json());
            }

            return res.blob()
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
        });
    }

    public async getViewerData<T>(type: string): Promise<T> {
        return fetch(`${this.getAPIDomain()}/beepbot/v2/viewers/${type}`, {
                headers: {
                    Authorization: `${localStorage.getItem('user:jwt')}`,
                    'Content-Type': 'application/json',
                }
            })
            .then(async res => {
                if (res.status === 204 || res.status === 401) {
                    return null;
                }

                return res.json()
                    .then(res => {
                        if (res == null) {
                            return [];
                        }

                        return res;
                    });
            });
    }

    public createLocalProject(channel: IChannel): IProject {
        return {
            id: channel.id,
            userId: channel.id,
            type: 'beepbot',
            linkedId: channel.id,
            scopes: [],
            owner: true,
            linked: {
                id: channel.id,
                name: channel.name,
                status: channel.status,
            }
        };
    }

    public getAPIDomain(): string | null {
        if (process.env['REACT_APP_DEV_API'] != null) {
            return process.env['REACT_APP_DEV_API'];
        }

        return process.env['REACT_APP_API_URI'] || null;
    }
}

export const api = new API();

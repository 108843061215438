import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import styles from './App.module.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { ColorExtractor } from 'react-color-extractor'

import { api } from './api/api';
import Button from './components/Button';
import useSession from './hooks/useSession';
import { openWindow } from './lib/openWindow';
import Icon from './components/Icon';
import Logo from './BeepCoin.svg';
import { hexToRgbA, idealTextColor } from './lib/colors';

function App() {
  const [active, setActive] = useState<string | null>(null);

  const [channels, setChannels] = useState<string[]>([]);
  const [colors, setColors] = useState<{ [key: string]: string[] }>({});

  const { session, setSession, logout } = useSession();

  const openModal = async (service: string) => {
    setActive(service);

    const { url } = await api.getAuthUrl(service);
    openWindow(url);
  };

  useEffect(() => {
    window.addEventListener('message', async ({ data, origin }) => {
      if ( origin !== process.env.REACT_APP_API_URI ) return;
      
      if (data && (data.type === 'jwt' && active === data.provider) ) {
        setSession({
          token: data.jwt
        });

        setActive(null);
      }

    });

    return () => {
      window.removeEventListener('message', () => null);
    }
  } , [active, setSession]);

  const getChannels = useCallback(async () => {
    let data: any[] = [];
    if ( session.token ) {
      data = await api.getViewerData('currencies');
    }
    setChannels(data);
  }, [session]);

  const getColors = (key: string, colors: string[]) => {
    setColors(orig => ({
      ...orig,
      [key]: colors,
    }));
  }

  useEffect(() => {
    getChannels();
  } , [session]);

  const bullets = [...channels.map(({ channel }: any) => `https://api.synerate.com/beepbot/v2/channels/${channel.id}/avatar`)];
  return session.token
    ? <div className={styles.App}>
        <div className={styles.appTitle}>
          <h1>
            <img src={Logo} alt="Logo" width={40} height={40} className={styles.loggedInLogo} /> Beep Coin
          </h1>
          <div>
            {session?.myself?.userName} 
            <Button size='small' variant='contained' onClick={() => logout()}>Logout</Button>
          </div>
        </div>
        <Swiper
          pagination={{
            renderBullet: (index: number, className: string) => `<img src="${bullets[index]}" class="${styles.bullet} ${className}" />`,
          }}
          className={styles.swiper}
        >
          {channels?.map(({ channel, currencies, user }: any) => {
            const channelColors = colors[channel.id];

            return <SwiperSlide
              key={channel.id}
              className={styles.swiperSlide}
            >
              <PullToRefresh onRefresh={getChannels} className={styles.pullToRefresh}>
                <>
                  <div className={styles.channel} style={{ color: channelColors?.[1] }}>
                    <ColorExtractor getColors={(colors: any) => getColors(channel.id, colors)}>
                      <img alt={channel.name + ' logo'} src={`https://api.synerate.com/beepbot/v2/channels/${channel.id}/avatar`} className={styles.avatar} style={{ borderColor: channelColors?.[1], boxShadow: channelColors?.[0] && `${hexToRgbA(channelColors?.[0], .8)} 0px 20px 30px -10px` }} />
                    </ColorExtractor>
                    {channel.name}
                  </div>
                  <div className={styles.cardsContainer}>
                    <Swiper
                      effect='cards'
                      pagination={{
                        dynamicBullets: true,
                      }}
                      className={styles.swiper}
                    >
                      {currencies.map(({ id, name, amount, dailyClaimed }: any) => (
                        <SwiperSlide key={id} className={styles.cardContainer}>
                          {/* {!dailyClaimed &&
                            <div className={styles.daily}>
                              You haven't claimed your daily points today
                              <br />
                              You can do so when {channel.name} goes live
                            </div>
                          } */}
                          <div className={styles.currencyCard} style={{ borderColor: channelColors?.[0], backgroundColor: channelColors?.[1] && hexToRgbA(channelColors?.[1], .8), boxShadow: channelColors?.[0] && `${hexToRgbA(channelColors?.[0], .5)} 0px 20px 30px -10px` }}>
                            <div className={styles.cardTitle} style={{ color: channelColors?.[1] && idealTextColor(channelColors?.[1]) }}>{name} Card</div>
                            <div className={styles.name} style={{ color: channelColors?.[1] && idealTextColor(channelColors?.[1]) }}>{name}</div>
                            <div className={styles.amount} style={{ backgroundColor: channelColors?.[0], color: channelColors?.[1] && idealTextColor(channelColors?.[0]) }}><span>{name}:</span> {amount}</div>
                            <div className={styles.username}>
                              <Icon
                                name={user.provider}
                                brands={['twitch'].includes(user.provider)}
                                custom={['trovo', 'glimesh'].includes(user.provider)}
                                size={14}
                              /> {user.username}
                              <br />
                              {id.split(/(?!^)(?=(?:.{3})+$)/).join("-")}
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </>
              </PullToRefresh>
            </SwiperSlide>;
          })}
        </Swiper>
      </div>
    : <div className={styles.App}>
    <div className={styles.login}>
      <img src={Logo} width={128} height={128} className={styles.logo} />
      <div className={styles.loginTitle}>
        <h1>Welcome to Beep Coin</h1>
        <h2>Your personal BeepBot wallet manager</h2>
        <br />
        <h2>Login to get started</h2>
      </div>
      <div className={styles.buttons}>
        <div className={styles.button} onClick={() => openModal('glimesh')}>
          <Icon name="glimesh" custom />
          <span>Glimesh</span>
        </div>
        <div className={styles.button} onClick={() => openModal('twitch')}>
          <Icon name="twitch" brands />
          <span>Twitch</span>
        </div>
        <div className={styles.button} onClick={() => openModal('trovo')}>
          <Icon name="trovo" custom />
          <span>Trovo</span>
        </div>
      </div>
    </div>
  </div>;
}

export default App;

import clsx from 'clsx';
import { random } from 'lodash';
import { ReactNode, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './Tooltip.module.scss';

export interface ToolTipProps {
  title?: string | ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  children?: ReactNode;
}

declare global {
  interface DOMRect {
    toJSON?: () => any // allow this to be undefined.
  }
}

/**
 * TODO: flip if tooltip is off screen
 */

const Tooltip = ({ title, placement, children }: ToolTipProps) => {
  const tip = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [{ top, left, right, bottom, width, height, x, y }, setPosition] = useState<DOMRect>(new DOMRect());

  const key = random(0, 9999);

  const onMouseEnter = (e: any) => {
    const { top, left, right, bottom, width, height } = e.target.getBoundingClientRect();

    setPosition({ top, left, right, bottom, width, height, x, y });
    setOpen(true);
  }

  const getStyles = () => {
    const tipEl = tip.current;
    const offset = 5;

    let val: any = { top: bottom + offset, left: left + (width/2) - (tipEl ? (tipEl.clientWidth/2) : 0) };

    switch ( placement ) {
      case 'right':
        val = { top: top + (height/2) - (tipEl ? (tipEl.clientHeight/2) : 0), left: right + offset };

        break;
      case 'left':
        val = { top: top + (height/2) - (tipEl ? (tipEl.clientHeight/2) : 0), left: left - (tipEl ? tipEl.clientWidth : 0) - offset };

        /**
         * flip if tooltip is off screen
         */
        if ( val.left < 0 ) {
          val.left = right + offset;
        }

        break;
      case 'top':
        val = { top: top - height - offset, left: left + (width/2) - (tipEl ? (tipEl.clientWidth/2) : 0) };

        break;
    }

    return val;
  }

  return title
    ? <span
      contentEditable={false}
      className={ styles.wrapper }
      onMouseEnter={ onMouseEnter }
      onMouseLeave={() => setOpen(false)}
      onClick={() => setOpen(false)}
    >
      { (title && document.getElementById('tooltip-container')) &&
        createPortal(
            <div
              className={ clsx(styles.tooltip, {
                [styles.open]: open
              }) }
            >
              <span ref={tip} style={getStyles()}>
                {title}
              </span>
          </div> as any,
          document.getElementById('tooltip-container') as Element,
          `tooltip_${key}`
        )
      }
      { children }
    </span>
    : <>
      { children}
    </>;
};

export const TooltipContainer = ({ children }: any) => {
  return <div className={ styles.root } id="tooltip-container">{ children }</div>;
};

export default Tooltip;
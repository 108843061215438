import { IconName } from '@fortawesome/fontawesome-common-types';
import clsx from 'clsx';

export interface IconProps {
  regular?: true;
  solid?: boolean;
  brands?: boolean;
  light?: boolean;
  thin?: boolean;
  duotone?: boolean;
  custom?: boolean;
  name: IconName | "synerate" | "synerate-outline" | "trovo" | "glimesh" | "beepbot-pro";
  size?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
}

const Icon = ({ regular, solid, brands, light, thin, duotone, custom, name, size, className, style, color }: IconProps) => (
  <i
    contentEditable={false}
    className={ clsx(
      'fa-regular',
      {
        'fa-brands': brands,
        'fa-solid': solid,
        'fa-regular': regular,
        'fa-light': light,
        'fa-thin': thin,
        'fa-duotone': duotone,
        'fak': custom,
      },
      `fa-${name}`,
      className,
    ) }
    style={{ height: size || 20, width: size || 20, color, ...style }}
  />
)

export default Icon;